import React from 'react';
import Text from './Text04';
import NavMain from './Nav04';
import Grid from './Grid04';

export default function Places() {
    return (
        <>
            <header className="head-wrap">
                <Text />
                <NavMain />
            </header>
            <div className="outer">
                <Grid />
            </div>
        </>
    );
}
