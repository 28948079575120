import React from 'react';
import TextStart from './Text00';
import NavMain from './Nav00';
import GridStart from './Grid00';

export default function Gridea() {
    return (
        <>
            <header className="head-wrap">
                <TextStart />
                <NavMain />
            </header>
            <div className="outer">
                <GridStart />
            </div>
        </>
    );
}
