import React from 'react';

export default class Grid extends React.Component {

    constructor() {
        super();
        this.state = {

        };
    }

    render() {
        return (
            <ul className='gr00  mar-20'>
                <li className='cell'>P</li>
                <li className='cell'>L</li>
                <li className='cell'>A</li>
                <li className='cell'>C</li>
                <li className='cell'>E</li>
                <li className='cell'>S</li>
                <li className='cell'><div className='cerchio bg-fc0'></div></li>
                <li className='cell'><div className='cerchio bg-teal'></div></li>
                <li className='cell'><div className='cerchio bg-red'></div></li>
            </ul>
        );
    }

}
