import React from 'react';

export default class Grid extends React.Component {

    constructor() {
        super();
        this.state = {

        };
    }

    render() {
        return (
            <ul className='gr00  mar-20'>
                <li className='cell'><div className='cerchio bg-fc0 pulse s8'></div></li>
                <li className='cell'>HTML</li>
                <li className='cell'>CSS</li>
                <li className='cell'>JavaScript</li>
                <li className='cell'>JSX</li>
                <li className='cell'>ReactJS</li>
                <li className='cell'>NodeJS</li>
                <li className='cell'>MongoDB</li>
                <li className='cell'><div className='cerchio bg-teal pulse s8'></div></li>
                <li className='cell'>Audio</li>
                <li className='cell'>Video</li>
                <li className='cell'>A/V</li>
                <li className='cell'>Final Cut Pro</li>
                <li className='cell'>Max/MSP/Jitter</li>
                <li className='cell'><div className='cerchio bg-red pulse s8'></div></li>
                <li className='cell'>Basketball</li>
                <li className='cell'>Cycling</li>
                <li className='cell'>Walking</li>
            </ul>
        );
    }

}
