import React from 'react';
import './App.css';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import Gridea from './components/Gridea';
import Stack from './components/Stack';
import Pulses from './components/Pulses';
import Media from './components/Media';
import Places from './components/Places';


function App() {
  return (
    <Router>
      <div className='App'>
        <Switch>
          <Route exact path='/' component={Gridea} />
          <Route exact path='/stack' component={Stack} />
          <Route exact path='/pulses' component={Pulses} />
          <Route exact path='/media' component={Media} />
          <Route exact path='/places' component={Places} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
