import React from 'react';
import Text from './Text02';
import NavMain from './Nav02';
import Grid from './Grid02b';

export default function Pulses() {
    return (
        <>
            <header className="head-wrap">
                <Text />
                <NavMain />
            </header>
            <div className="outer">
                <Grid />
            </div>
        </>
    );
}
