import React from 'react';
import Text from './Text01';
import NavMain from './Nav01';
import Grid from './Grid01';

export default function Stack() {
    return (
        <>
            <header className="head-wrap">
                <Text />
                <NavMain />
            </header>
            <div className="outer">
                <Grid />
            </div>
        </>
    );
}
