import React from 'react';

// this code 100% original by nicola grassini, june 2019
export default class Grid extends React.Component {

    constructor() {
        super();
        this.state = {
            pulseDuration: '',
            pulseSize: '',
            pulseColor: ''
        };
    }

    durationSame() {
        let options = ['0.5s', '1s', '2s', '4s', '8s'];
        let newDuration = options[Math.floor(Math.random() * (options.length))];

        this.setState({
            pulseDuration: newDuration
        },
            () => {
                let x = document.getElementsByClassName("cerchio");
                for (let i = 0; i < x.length; i++) {
                    x[i].style.animationDuration = this.state.pulseDuration;
                }
            }
        );
    }

    sizeSame() {
        let options = ['20px', '40px', '60px', '80px', '100px'];
        let newSize = options[Math.floor(Math.random() * (options.length))];

        this.setState({
            pulseSize: newSize
        },
            () => {
                let x = document.getElementsByClassName("cerchio");
                for (let i = 0; i < x.length; i++) {
                    x[i].style.width = this.state.pulseSize;
                    x[i].style.height = this.state.pulseSize;
                }
            }
        );
    }

    colorSame() {
        let options = ['#000', '#111', '#222', '#333', '#444', '#555', '#666', '#777', '#888', '#999', '#aaa', '#bbb', '#ccc', '#ddd', '#eee'];
        let newColor = options[Math.floor(Math.random() * (options.length))];

        this.setState({
            pulseColor: newColor
        },
            () => {
                let x = document.getElementsByClassName("cerchio");
                for (let i = 0; i < x.length; i++) {
                    x[i].style.backgroundColor = this.state.pulseColor;
                }
            }
        );
    }
    // classChanger() {
    //     this.setState({
    //         cellDuration: '8s'
    //     });
    // }
    durationRandom() {
        let options = ['0.5s', '1s', '2s', '4s', '8s'];
        //let newDuration = options[Math.floor(Math.random() * (options.length))];

        this.setState({
            pulseDuration: 'randomised' // newDuration
        },
            () => {
                let x = document.getElementsByClassName("cerchio");
                for (let i = 0; i < x.length; i++) {
                    x[i].style.animationDuration = options[Math.floor(Math.random() * (options.length))];
                }
            }
        );
    }

    sizeRandom() {
        let options = ['20px', '40px', '60px', '80px', '100px'];
        //let newSize = options[Math.floor(Math.random() * (options.length))];

        this.setState({
            pulseSize: 'randomised' //newSize
        },
            () => {
                let x = document.getElementsByClassName("cerchio");
                for (let i = 0; i < x.length; i++) {
                    x[i].style.width = options[Math.floor(Math.random() * (options.length))];
                    x[i].style.height = x[i].style.width;
                }
            }
        );
    }

    colorRandom() {
        let options = ['#000', '#111', '#222', '#333', '#444', '#555', '#666', '#777', '#888', '#999', '#aaa', '#bbb', '#ccc', '#ddd', '#eee'];
        // let newColor = options[Math.floor(Math.random() * (options.length))];

        this.setState({
            pulseColor: 'randomised' //newColor
        },
            () => {
                let x = document.getElementsByClassName("cerchio");
                for (let i = 0; i < x.length; i++) {
                    x[i].style.backgroundColor = options[Math.floor(Math.random() * (options.length))];
                }
            }
        );
    }

    render() {
        return (
            <>
                <div className='gr01lo mar-20'>
                    <p className='cell point bg-f is-red txt-c pad-10' onClick={() => this.durationSame()}>
                        change speed (equal)
                    </p>
                    <p className='cell point bg-f is-fc0 txt-c pad-10' onClick={() => this.sizeSame()}>
                        change size (equal)
                    </p>
                    <p className='cell point bg-f is-teal txt-c pad-10' onClick={() => this.colorSame()}>
                        change color (equal)
                    </p>
                    <p className='cell point bg-f is-red txt-c pad-10' onClick={() => this.durationRandom()}>
                        change speed (random)
                    </p>
                    <p className='cell point bg-f is-fc0 txt-c pad-10' onClick={() => this.sizeRandom()}>
                        change size (random)
                    </p>
                    <p className='cell point bg-f is-teal txt-c pad-10' onClick={() => this.colorRandom()}>
                        change color (random)
                    </p>
                </div>
                <ul className='gr01 mar-10'>
                    <li className='cell'><div className='wh60 cerchio bg-0 pulse s2'></div></li>
                    <li className='cell'><div className='cerchio bg-1 pulse s1'></div></li>
                    <li className='cell'><div className='wh40 cerchio bg-2 pulse s2'></div></li>
                    <li className='cell'><div className='wh80 cerchio bg-3 pulse s4'></div></li>
                    <li className='cell'><div className='wh20 cerchio bg-4 pulse s2'></div></li>
                    <li className='cell'><div className='cerchio bg-5 pulse s2'></div></li>

                    <li className='cell'><div className='wh20 cerchio bg-3 pulse s4'></div></li>
                    <li className='cell'><div className='wh40 cerchio bg-4 pulse s2'></div></li>
                    <li className='cell'><div className='wh80 cerchio bg-5 pulse s2'></div></li>
                    <li className='cell'><div className='cerchio bg-6 pulse s1'></div></li>
                    <li className='cell'><div className='wh80 cerchio bg-7 pulse s4'></div></li>
                    <li className='cell'><div className='wh60 cerchio bg-8 pulse s2'></div></li>

                    <li className='cell'><div className='wh60 cerchio bg-6 pulse s2'></div></li>
                    <li className='cell'><div className='wh40 cerchio bg-7 pulse s4'></div></li>
                    <li className='cell'><div className='wh80 cerchio bg-8 pulse 
                    s1'></div></li>
                    <li className='cell'><div className='wh60 cerchio bg-9 pulse s8'></div></li>
                    <li className='cell'><div className='wh40 cerchio bg-a pulse s2'></div></li>
                    <li className='cell'><div className='wh80 cerchio bg-b pulse s4'></div></li>

                    <li className='cell'><div className='cerchio bg-9 pulse s1'></div></li>
                    <li className='cell'><div className='wh60 cerchio bg-a pulse s2'></div></li>
                    <li className='cell'><div className='wh20 cerchio bg-b pulse s1'></div></li>
                    <li className='cell'><div className='cerchio bg-c pulse s8'></div></li>
                    <li className='cell'><div className='cerchio bg-d pulse s4'></div></li>
                    <li className='cell'><div className='wh60 cerchio bg-e pulse s2'></div></li>
                </ul>
            </>
        );
    }

}
