import React from 'react';
import { NavLink } from 'react-router-dom';

export default function NavMain() {
    return (
        <ul className='nav-main'>
            <li><NavLink className='a3 space-25' title='stack' to='/stack'>stack</NavLink></li>
            <li><NavLink className='a3 space-25' title='pulses' to='/pulses'>pulses</NavLink></li>
            {/* <li className='space-25'>gridea</li> */}
            {/* <li><a href='http://www.nicolag.it' className='a3 space-25' target='_blank' title='nicolag.it'>nicolag</a></li> */}
        </ul>
    );
}